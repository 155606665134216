import { shopInfo, areaName, hvnUriRoot,slideArr } from "./core/defines";

const enterbanner = {
    pc: "/img/slides/20241101/s01-pc.jpg",
    sp: "/img/slides/20241101/s01-sp.jpg",
    /*
    pc: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue",
    sp: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue&sp=t",
    */
}
// console.log(enterbanner)

const hvnEnter = "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/?of=y2"

const recruitLink={
    men: "https://mens-qzin.jp/ibaraki/area_08001/detail/marinebluemito/?v=official",
    women: "https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/mt-marin-blue/?of=y",
}

const outsideLinks=`
<nav class="links">
    <div class="bnr"><a href="https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/mt-marin-blue/?of=y"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ガールズ版はこちら.png" width="640px"></a></div>
    <div class="bnr"><a href="https://mensheaven.jp/17/mt-marin-blue/?of=y"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ジョブ版はこちら.png" width="640px"></a></div>
    <div class="bnr"><a href="https://www.cityheaven.net/ibaraki/A0801/A080101/shop-list/biz4/" target="_blank"><img src="https://img.cityheaven.net/img/mutual_link/468_68_28.jpg" width="640px" border="0" alt="水戸のソープ｜シティヘブンネット"></a></div>
    <div class="bnr"><a href="https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/" target="_blank"><img src="https://img.girlsheaven-job.net/img/fppc/468_68_28.png" width="640px" border="0" alt="水戸の風俗求人｜ガールズヘブン"></a></div>
    <div class="bnr"><a href="https://mensheaven.jp/16/ibaraki/ma-165/shop-list/" target="_blank"><img src="https://img.mensheaven.jp/img/fppc/bnr/074.jpg" width="640px" border="0" alt="水戸のスタッフ求人｜ジョブヘブン"></a></div>
    <div class="bnr"><a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner"><img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="640px" alt="マリングループ"></a></div>
    <div class="bnr"><a rel="nofollow" href="https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/?of=y" alt="当店ヘブン版はこちら" target="_blank"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ヘブン版はこちら.png" width="640px" alt="バニラ求人"></a></div>
    <div class="bnr"><a rel="nofollow" href="https://kitakanto.qzin.jp/mitomarineblue/?v=official" target="_blank"><img src="https://ad.qzin.jp/img/vanilla468-60.gif" width="640px" alt="バニラ求人"></a></div>
    <div class="bnr"><a rel="noforrow" href="https://ranking-deli.jp/fuzoku/style5/13/" target="_blank"><img src="https://ranking-deli.jp/assets/img/user/link/2018bnr01.jpg" alt="[駅ちか]で探す茨城のヘルス情報" width="640px"></a></div>
</nav>
`

const navs = [];
function Entrance(){
    return(
        <article id="entrance" className="txt-center">
            <header>{ areaName }のソープランド 【{ shopInfo.name }】</header>
            <picture>
                <source media="(max-width: 720px)" srcSet={enterbanner.sp} />
                <source media="(min-width: 721px)" srcSet={enterbanner.pc} />
                <img src={enterbanner.pc} alt={ `${ areaName }のソープランド 【${ shopInfo.name }】` } />
            </picture>
            <div className="wrappar">
                <p className="w-100 kin-txt">
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。
                </p>
                <a className="exit" href="https://www.yahoo.co.jp" nofollow>18歳未満の方は<u>コチラ</u>からご退出ください。</a>

                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>                        
                        </ul>
                    </div>
                </div>

                <nav className="enter">
                    <ul>
                        <li><a className="btn to-home" href="/home"><span>ENTER</span>18歳以上のお客様ページ</a></li>
                        <li><a className="btn to-hvn" href={ hvnEnter }>ヘブン版ページはコチラ</a></li>
                    </ul>
                </nav>
                
                <section className="recruit">
                    <ul className="box">
                        <li><a className="btn women" href={recruitLink.women}><span>女性求人</span>ENTER</a></li>
                        <li><a className="btn men"   href={recruitLink.men}><span>男性求人</span>ENTER</a></li>
                    </ul>
                </section>
                
                {/* ヘブンバナー類 直書きのstyleの記述方が違うので注意。特にプロパティ名がlowCamelCaseの点に注意 */}
                <div class="enterbanner"
                    dangerouslySetInnerHTML={{
                        __html: outsideLinks
                    }}>
                </div>
            </div>
        </article>
    );
}

export default Entrance;